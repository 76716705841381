.searchinputshort{
	width:120px;
}
.searchinputnormal{
	width:160px;
}
.searchinputlong{
	width:200px;
}
.searchconditionbtn{
	margin:5px 5px 0 0;
}
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}
#adduserform  .ant-form-item {
  margin-bottom: 0 !important;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
#inputVal .ant-col-12{
	float: initial;
	display: inline-block;
}
